import {
  BrowserRouter,
  Routes,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import Root from "./routes/root";
import ErrorPage from "./common/error/error-page";
import Contact from "./routes/contact";
import { AuthContextProvider } from "./common/context/auth-context";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "contacts/:contactId",
        element: <Contact />,
      },
    ],
  },
]);

function App() {
  return (
    <AuthContextProvider>
       <RouterProvider router={router} />
    </AuthContextProvider>
  );
}

export default App;
