import React, { useState } from "react";

export const AuthContext = React.createContext(null);

export const AuthContextProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}